<template lang="pug">
  tr(:key="`${item.id}-row`" :active="isSelected" :class="{'updated' : item.animation}" data-test="candidate-row")
    slot(name="select")

    td.app-table__cell.app-table__cell_small.pr-2.pl-3
      .d-flex.align-center
        btn-icon(@click="goToEdit({expand: expand, isExpanded: isExpanded})").mr-2
          v-icon(:class="{'expanded': isExpanded}") mdi-chevron-down
        span {{ item.last_application_date | formattedDate }}

    td.task-cell-name {{ firstApp.name }}

    td.task-cell-phone {{ firstApp.phone }}

    td.task-cell-email {{ firstApp.email }}

    td.task-cell-similar {{ item.applications.length }}
      slot(name="similar")
        similar(v-if="item.similar_tasks_count" :item="item" @merged="$emit('needToUpdate')")

    td.task-cell-due-date
      due-date(:item="item")

    td.task-cell-status
      span(v-if="firstApp && firstApp.status && (firstApp.status !== 'OPEN')") {{ TASK_APPLICATIONS_TYPES_LIST[firstApp.status].label }}
      span(v-else-if="firstApp && firstApp.status && (firstApp.status === 'OPEN')") Open
      span(v-else) -

    td.task-cell-notes
      slot(name="log")
        task-notes(ref="taskAppLog" :ID="item.id" :logs="item.logs" @change:log="updateLogs")
          template(v-slot:send)
            slot(name="log-send")
</template>

<script>
import BtnIcon from "@/components/global/BtnIcon.vue"
import Notes from "@/app/admin/modules/candidates/components/candidates/CandidatesNote.vue"
import { state } from './TaskAppNotes.vue'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'
import { TASK_APPLICATIONS_TYPES_LIST } from '@/app/admin/modules/task_applications/core/taskApplications-const'
import errorsMixin from '@/mixins/errors.mixin'

export default {
  props: {
    isSelected: Boolean,
    isExpanded: Boolean,
    expand: Function,
    select: Function,
    item: {type: Object, required: true},
    index: Number,
    pagination: Object,
    primaryPagination: Object,
    moreThanOnePageVisible: Boolean,
    activeItems: Array,
    labels: Array,
    loading: Boolean
  },

  mixins: [errorsMixin, featureFlagsMixin],

  inject: {
    updateInList: 'updateItem',
    applicationService: 'applicationService'
  },

  data: () => ({
    TASK_APPLICATIONS_TYPES_LIST
  }),

  computed: {
    firstApp() {
      return {
        ...this.item,
        ...this.item.applications[0]
      }
    },

    isTaskAppLogActive() {
      return state.active.length
    }
  },
  methods: {
    goToEdit({ID, expand, isExpanded}) {
      this.$emit('onGoToEdit', {expand, isExpanded})
      if (this.isTaskAppLogActive > 0) this.$refs.taskAppLog.toggleBar()
    },

    updateNotes(val) {
      this.item.notes = val
    },

    updateLogs({res, due_date}) {
      this.updateInList(this.item.id, 'logs', [res, ...this.item.logs])
      if (due_date) this.updateInList(this.item.id, 'due_date', due_date)
      this.$emit('needToUpdate');
    }
  },

  components: {
    btnIcon: BtnIcon,
    notes: Notes,
    dueDate: () => import('./TaskAppDueDate.vue'),
    similar: () => import('./TaskAppSimilar.vue'),
    taskNotes: () => import('./TaskAppNotes.vue'),
    active: () => import('./TaskApplicationsActive')
  }
}
</script>

<style lang="scss" scoped>
.expanded {
  transform: rotate(180deg);
}

.task-cell-is-active {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-cell-due-date {
  cursor: pointer;
}

.task-cell-notes {
  max-width: 150px;
  cursor: pointer;
}
</style>
